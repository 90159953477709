import { WidgetType } from '@celum/portals/domain';
import { WidgetInfo } from '@celum/portals/shared';

import type { AssetGalleryConfiguration } from './lib/asset-gallery-widget.model';

export const ASSET_GALLERY_WIDGET: WidgetInfo<AssetGalleryConfiguration> = {
  type: WidgetType.ASSET_GALLERY,
  defaultConfiguration: () => ({
    facets: [
      {
        name: 'PORTALS.FACETS.GENERAL',
        id: 'general',
        columns: [
          {
            facets: [
              {
                fieldId: 'file_format',
                fieldType: 'SELECTION',
                facetType: 'SELECTION',
                behavior: 'EXPANDING',
                title: 'PORTALS.FACETS.FORMAT',
                facetId: 1
              }
            ]
          },
          {
            facets: [
              {
                fieldId: 'original_file_size',
                fieldType: 'SELECTION',
                title: 'PORTALS.FACETS.SIZE',
                facetType: 'SELECTION',
                behavior: 'EXPANDING',
                facetId: 2
              }
            ]
          }
        ]
      }
    ],
    paginationMode: 'infinite-scroll',
    librariesMode: 'filtered',
    libraryIds: [],
    style: {
      primary: { id: 'primary' },
      contrast: { id: 'contrast' }
    }
  }),
  thumb: 'widget-icon-gallery',
  name: 'EXPERIENCE.PORTAL_DESIGNER.WIDGET.ASSET_GALLERY_WIDGET.NAME',
  description: {},
  widgetComponent: () => import('./lib/asset-gallery-widget.component').then(module => module.AssetGalleryWidgetComponent)
};
